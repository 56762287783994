/*Copyright Epic Games, Inc. All Rights Reserved.*/

:root {
  /*Using colour scheme https://color.adobe.com/TD-Colors---Option-3-color-theme-10394433/*/
  --colour1: #2b3a42;
  --colour2: #242424;
  --colour3: #bdd4de;
  --colour4: #b0b0b0;
  --colour5: #0262c3;
  --colour6: #0f0f0f;
  --colour7: #2f2f2f;
  --colour8: #151515;

  --buttonFont: Arial;
  --inputFont: Arial;
}

body {
  margin: 0px;
  /* background-color: black; */
}

#playerUI {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.statsContainer {
  background-color: var(--colour6);
  text-align: left;
  display: block;
  margin-top: 5px;
}

.stats {
  font-size: 14px;
  font-weight: bold;
  padding: 6px;
  color: var(--colour5);
}

canvas {
  image-rendering: crisp-edges;
  position: absolute;
}

video {
  /* 12.9-inch iPad Pro. */
  position: absolute;
  /* top: -160px;
  left: -448px;
  width: 2732px;
  height: 1348px; */
  height: 100vh;
}

#player {
  position: fixed;
}

#overlay {
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; /* future proofing */
  border-bottom-left-radius: 5px; /* future proofing */
  -khtml-border-bottom-right-radius: 5px; /* for old Konqueror browsers */
  -khtml-border-bottom-left-radius: 5px; /* for old Konqueror browsers */

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  position: absolute;
  top: 0;
  right: 2%;
  z-index: 100;
  border: 4px solid var(--colour8);
  border-top-width: 0px;
}

.overlay {
  background-color: var(--colour2);
  font-family: var(--buttonFont);
  font-weight: lighter;
  color: var(--colour4);
}

.overlay-shown > #overlaySettings {
  display: block;
}

.overlay-shown > div > #overlayButton {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); /* Safari */
  -moz-transform: rotate(-135deg); /* Firefox */
  -ms-transform: rotate(-135deg); /* IE */
  -o-transform: rotate(-135deg); /* Opera */
}

#overlayButton:hover {
  cursor: pointer;
}

#overlayButton {
  transition-duration: 250ms;
  text-align: right;
  font-size: 40px;
}

#qualityStatus {
  font-size: 37px;
  padding-right: 4px;
}

.setting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 10px 2px 10px;
}

#overlaySettings {
  font-size: 1em;
  width: 400px;
  display: none;
}

.greyStatus {
  color: grey;
}

.limeStatus {
  color: lime;
}

.orangeStatus {
  color: orange;
}

.redStatus {
  color: red;
}

#videoMessageOverlay {
  z-index: 20;
  color: var(--colour4);
  font-size: 1.8em;
  position: absolute;
  margin: auto;
  font-family: var(--inputFont);
  width: 100%;
}

#videoPlayOverlay {
  z-index: 30;
  position: absolute;
  color: var(--colour4);
  font-size: 1.8em;
  font-family: var(--inputFont);
  width: 100%;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.7);
}

/* State for element to be clickable */
.clickableState {
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

/* State for element to show text, this is for informational use*/
.textDisplayState {
  display: flex;
}

/* State to hide overlay, WebRTC communication is in progress and or is playing */
.hiddenState {
  display: none;
}

#playButton {
  display: inline-block;
  height: auto;
}

img#playButton {
  max-width: 241px;
  width: 10%;
}

#UIInteraction {
  position: fixed;
}

#UIInteractionButtonBoundary {
  padding: 2px;
}

#UIInteractionButton {
  cursor: pointer;
}

#hiddenInput {
  position: absolute;
  left: -10%; /* Although invisible, push off-screen to prevent user interaction. */
  width: 0px;
  opacity: 0;
}

#editTextButton {
  position: absolute;
  height: 40px;
  width: 40px;
}

#overlayHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--colour8);
  background-color: var(--colour7);
}

section {
  display: flex;
  flex-direction: column;
}

section > :first-child {
  background-color: var(--colour7);
  padding: 4px;
  font-weight: bold;
  border-top: 1px solid var(--colour8);
  border-bottom: 1px solid var(--colour8);
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.subtitle-text {
  font-style: italic;
  font-size: 0.6em;
}

.settings-text {
  color: var(--colour4);
  vertical-align: middle;
  font-weight: normal;
}

.overlay-button {
  line-height: 1.1;
  padding: 1px 6px;
}

.btn-overlay {
  vertical-align: middle;
  display: inline-block;
}

.btn-flat {
  background: var(--colour7);
  border: 2px solid var(--colour8);
  color: var(--colour4);
  font-weight: bold;
  cursor: pointer;
  font-family: var(--buttonFont);
  font-size: 10px;
  border-radius: 5px;
  height: 20px;
  text-align: center;
}

.btn-flat:disabled {
  background: var(--colour8);
  border-color: var(--colour6);
  cursor: not-allowed;
}

.btn-flat:hover:enabled {
  outline: none;
  background: var(--colour2);
  border-color: var(--colour4);
}

.btn-flat:active:enabled {
  background: var(--colour8);
  border-color: var(--colour6);
  color: var(--colour7);
}

/*** Toggle Switch styles ***/
.tgl-switch {
  vertical-align: middle;
  display: inline-block;
}

.tgl-switch .tgl {
  display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-slider {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-slider::-moz-selection {
  background: none;
}
.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-slider::selection {
  background: none;
}

.tgl + .tgl-slider {
  outline: 0;
  display: block;
  width: 40px;
  height: 18px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tgl + .tgl-slider:after,
.tgl + .tgl-slider:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.tgl + .tgl-slider:after {
  left: 0;
}
.tgl + .tgl-slider:before {
  display: none;
}

.tgl-flat + .tgl-slider {
  padding: 2px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: var(--colour6);
  border: 3px solid var(--colour4);
  border-radius: 2em;
}

.tgl-flat + .tgl-slider:after {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: var(--colour4);
  content: "";
  border-radius: 1em;
}

.tgl-flat:checked + .tgl-slider {
  border: 3px solid var(--colour5);
}

.tgl-flat:checked + .tgl-slider:after {
  left: 50%;
  background: var(--colour5);
}
/*** Toggle Switch styles ***/

#encoderSettings,
#webRTCSettings {
  margin: 10px 0px;
}

.collapse {
  padding-left: 5%;
}

input[type="number"]:disabled {
  background: var(--colour7);
  border: none;
  color: var(--colour4);
  cursor: not-allowed;
}

select {
  text-align: right;
}

input {
  text-align: right;
}

.form-control {
  background-color: var(--colour6);
  border: 2px solid var(--colour7);
  border-radius: 4px;
  color: var(--colour4);
}

.form-control:hover {
  border-color: var(--colour4);
}

.form-group {
  padding-top: 4px;
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 4px;
  padding-right: 10px;
  padding-left: 10px;
}

.form-group label {
  color: var(--colour4);
  vertical-align: middle;
  font-weight: normal;
}

#latencyTest {
  display: block;
}

#latencyTest button {
  margin: 30px 0px;
}

#freezeFrameOverlay {
  background-color: transparent;
}

.freezeframeBackground {
  background-color: #000 !important;
}
