.mainContainer {
  display: flex;
  margin: auto;
  flex-direction: column;
  width: 700px;
  height: 100vh;
  justify-content: center;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  padding: 30px;
}

input {
  padding: 10px;
  border: 1px solid black;
  margin: 10px;
  font-family: var(--font-family-easyjet-rounded-book);
  font-size: large;
  text-align: left;
}

button {
  margin: 10px;
  padding: 10px;
  font-family: var(--font-family-easyjet-rounded-book);
  font-size: large;
}

.error {
  color: rgb(238, 40, 40);
  margin: 0px 10px 10px 10px;
  font-size: medium;
  font-family: var(--font-family-easyjet-rounded-book);
}

label {
  margin: 10px 10px 0px 10px;
  font-family: var(--font-family-easyjet-rounded-book);
  font-size: large;
}
