/*
 *         Filename: index.css
 *    Last Modified: 2022-11-13
 *          Version: 1.0.0
 */

/* -----------------------------------------------------------------------------
 * External Fonts
 * -------------------------------------------------------------------------- */
@font-face {
  font-display: block;
  font-family: "easyjet_rounded_bookRg";
  src: url("fonts/easyjet_rounded_book-webfont.eot");
  src: url("fonts/easyjet_rounded_book-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/easyjet_rounded_book-webfont.woff") format("woff"),
    url("fonts/easyjet_rounded_book-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-display: block;
  font-family: "easyjet_rounded_demiRg";
  src: url("fonts/easyjet_rounded_demi-webfont.eot");
  src: url("fonts/easyjet_rounded_demi-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/easyjet_rounded_demi-webfont.woff") format("woff"),
    url("fonts/easyjet_rounded_demi-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-display: block;
  font-family: "easyjet_rounded_headlineRg";
  src: url("fonts/easyjet_rounded_headline-webfont.eot");
  src: url("fonts/easyjet_rounded_headline-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/easyjet_rounded_headline-webfont.woff") format("woff"),
    url("fonts/easyjet_rounded_headline-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-display: block;
  font-family: "easyjet_rounded_lightRg";
  src: url("fonts/easyjet_rounded_light-webfont.eot");
  src: url("fonts/easyjet_rounded_light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/easyjet_rounded_light-webfont.woff") format("woff"),
    url("fonts/easyjet_rounded_light-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-display: block;
  font-family: "easyjet_generation_headline";
  src: url("fonts/easyjet_generation_headline-webfont.eot");
  src: url("fonts/easyjet_generation_headline-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/easyjet_generation_headline-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

/* -----------------------------------------------------------------------------
 * Global
 * -------------------------------------------------------------------------- */
* {
  margin: 0;
  padding: 0;
  border: 0;
}

/* -----------------------------------------------------------------------------
 * Chatbot
 * -------------------------------------------------------------------------- */
.chatbot #div-translucent-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-dark-reduced);
  pointer-events: none;
  opacity: 0;
  transition: var(--transition-all-quadratic);
}
.chatbot #div-translucent-overlay-icon-speech {
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
  padding: var(--space);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--button-size-large);
  width: var(--button-size-large);
  background-color: var(--color-orange);
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: var(--transition-all-quadratic);
}

.chatbot.main {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 255;
}
.chatbot.main section {
  pointer-events: auto;
}

.chatbot.section-botmessage {
  margin-top: var(--space);
  margin-left: var(--space);
  max-width: var(--width-half);
  min-width: var(--width-minimum-half);
  color: var(--color-light);
  font-family: var(--font-family-easyjet-rounded-demi);
  font-size: var(--font-size);
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: var(--transition-all-quadratic);
}

.chatbot.section-inputpane {
  margin-bottom: var(--space);
  padding-top: var(--button-size);
  position: absolute;
  bottom: 0;
  height: var(--button-size);
  width: var(--width);
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
}
.chatbot.section-inputpane::-webkit-scrollbar {
  display: none;
}
.chatbot #section-inputpane-button-chips,
.chatbot #section-inputpane-button-left,
.chatbot #section-inputpane-button-right {
  max-width: var(--button-size);
  min-width: var(--button-size);
  display: block;
  flex-grow: 0;
  background-color: var(--color-orange);
  border-radius: 100%;
  border-style: none;
  border-width: 0px;
  transition: var(--transition-all-quadratic);
}
.chatbot #section-inputpane-button-chips,
.chatbot #section-inputpane-button-right {
  margin-right: var(--space);
}
.chatbot #section-inputpane-button-chips {
  display: none;
}
.chatbot #section-inputpane-button-left {
  margin-left: var(--space);
}
.chatbot #section-inputpane-chips {
  display: flex;
  flex-direction: row;
  pointer-events: none;
}
.chatbot #section-inputpane-chips button {
  margin-right: var(--space);
  padding-top: var(--font-size-half);
  padding-right: var(--font-size-three-quarters);
  padding-bottom: var(--font-size-half);
  padding-left: var(--font-size-three-quarters);
  min-width: calc(var(--button-size) * 2.75);
  color: var(--color-dark);
  font-family: var(--font-family-easyjet-rounded-book);
  font-size: var(--font-size-half);
  text-align: center;
  white-space: nowrap;
  background-color: var(--color-light);
  border-radius: 24px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: 1;
  transition: var(--transition-all-quadratic);
}
.chatbot.section-inputpane input {
  margin-right: var(--space);
  margin-left: var(--space);
  padding-top: var(--font-size-half);
  padding-right: var(--font-size-three-quarters);
  padding-bottom: var(--font-size-half);
  padding-left: var(--font-size-three-quarters);
  min-width: 66.6%;
  flex-grow: 1;
  color: var(--color-dark);
  font-family: var(--font-family-easyjet-rounded-book);
  font-size: var(--font-size-half);
  text-align: left;
  border-radius: 24px;
  transition: var(--transition-all-quadratic);
}
.chatbot.section-inputpane input:focus {
  min-width: calc(66.6% - calc(var(--border-width) * 2));
  outline: none !important;
  border-color: var(--color-orange);
  border-style: solid;
  border-width: var(--border-width);
}

/* -----------------------------------------------------------------------------
 * Root
 * -------------------------------------------------------------------------- */
:root {
  --border-radius: 24px;
  --border-width: 4px;
  --button-size: 96px;
  --button-size-large: 128px;
  --color-dark: #2a2a2a;
  --color-dark-reduced: rgba(18, 17, 17, 0.72);
  --color-orange: #ff6600;
  --color-light: #ffffff;
  --font-family-easyjet-rounded-book: "easyjet_rounded_bookRg", -apple-system,
    BlinkMacSystemFont, sans-serif;
  --font-family-easyjet-rounded-demi: "easyjet_rounded_demiRg", -apple-system,
    BlinkMacSystemFont, sans-serif;
  --font-family-easyjet-rounded-headline: "easyjet_rounded_headlineRg",
    -apple-system, BlinkMacSystemFont, sans-serif;
  --font-family-easyjet-rounded-light: "easyjet_rounded_lightRg", -apple-system,
    BlinkMacSystemFont, sans-serif;
  --font-family-easyjet_generation_headline: "easyjet_generation_headline",
    -apple-system, BlinkMacSystemFont, sans-serif;
  --font-size: 48px;
  --font-size-half: calc(var(--font-size) * 0.5);
  --font-size-three-quarters: calc(var(--font-size) * 0.75);
  --height: 100vh;
  --height-minimum: 1024px;
  --space: 48px;
  --transition-all-quadratic: all 0.32s cubic-bezier(0.33, 0.66, 0.66, 1);
  --width: 100vw;
  --width-minimum: 1366px;
  --width-minimum-half: calc(var(--width-minimum) * 0.5);
  --width-half: calc(var(--width) * 0.5);
}
